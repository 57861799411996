import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";
import React from "react";
import {
  ErrorBoundary,
  Facet,
  SearchProvider,
  SearchBox,
  Results,
  PagingInfo,
  ResultsPerPage,
  Paging,
  WithSearch,
} from "@elastic/react-search-ui";
import {
  BooleanFacet,
  Layout,
  Result,
  SingleLinksFacet,
  SingleSelectFacet
} from "@elastic/react-search-ui-views";
//added//////////////////////////////////


 import type {SearchContextState, SearchResult} from "@elastic/search-ui";

/////////////////////////////////////////
import "@elastic/react-search-ui-views/lib/styles/styles.css";
import "./dsu-style.css";
import { SearchDriverOptions } from "@elastic/search-ui";


const connector = new AppSearchAPIConnector({
  searchKey: "search-hjzwomoht1eigkacibr3jamz",
  engineName: "dsu-edu",
  endpointBase: "https://entsearch.dsu.edu:443"
});

const config: SearchDriverOptions = {
  alwaysSearchOnInitialLoad: true,
  apiConnector: connector,
  hasA11yNotifications: true,
  searchQuery: {
    result_fields: {
      title: { 
        snippet:{
          fallback: true
        } 
      },
      body: {
        snippet:{
          size: 250,
          fallback: true
        }
      },
      meta_description: {
        snippet:{
          size:250,
          fallback: true
        }
      },
      url: { raw:{} },
    },
    search_fields: {
      title: {},
      body: {},
      meta_description: {}
    },
    disjunctiveFacets: [""],
    facets: {}
  }
};


const CustomResultView = ({
  result,
  onClickLink
}: {
  result: SearchResult;
  onClickLink: () => void;
}) => (
  <li className="sui-result">
    <div className="sui-result__header">
        {/* Maintain onClickLink to correct track click throughs for analytics*/}
        <a className="sui-result__title sui-result__title-link" onClick={onClickLink} href={result.url.raw} dangerouslySetInnerHTML={{__html: result.title.snippet}}>
        </a>
        <div className="sui-result__url">
           <cite dangerouslySetInnerHTML={{__html: result.url.raw}}></cite>
        </div>
    </div>
    <div className="sui-result__body">
      <div
        className="sui-result__details"
        dangerouslySetInnerHTML={{ __html: result.body.snippet }}
      ></div>
    </div>
  </li>
);

export default function App() {
  return (
    <div>
    <SearchProvider config={config}>
      <WithSearch
        mapContextToProps={({ wasSearched }) => ({
          wasSearched
        })}
      >
        {({ wasSearched }) => {
          return (
            <div className="App">
              <ErrorBoundary>
                <Layout
                  header={<SearchBox debounceLength={0} autocompleteSuggestions={true} inputProps={{required: true}}/>}
                  sideContent={<div></div>}
                  bodyContent={
                    <Results
                      // titleField="title"
                      // urlField="url"
                      // thumbnailField="image_url"
                      shouldTrackClickThrough
                      resultView={CustomResultView}
                    />
                  }
                  bodyHeader={
                    <React.Fragment>
                      {wasSearched && <PagingInfo />}
                      {wasSearched && <ResultsPerPage />}
                    </React.Fragment>
                  }
                  bodyFooter={<Paging />}
                />
              </ErrorBoundary>
            </div>
          );
        }}
      </WithSearch>
    </SearchProvider>
    </div>
  );
}